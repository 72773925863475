exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-dewanai-tsx": () => import("./../../../src/pages/index_dewanai.tsx" /* webpackChunkName: "component---src-pages-index-dewanai-tsx" */),
  "component---src-pages-ja-login-tsx": () => import("./../../../src/pages/ja/login.tsx" /* webpackChunkName: "component---src-pages-ja-login-tsx" */),
  "component---src-pages-ja-register-tsx": () => import("./../../../src/pages/ja/register.tsx" /* webpackChunkName: "component---src-pages-ja-register-tsx" */),
  "component---src-pages-ja-sites-tsx": () => import("./../../../src/pages/ja/sites.tsx" /* webpackChunkName: "component---src-pages-ja-sites-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-sites-tsx": () => import("./../../../src/pages/sites.tsx" /* webpackChunkName: "component---src-pages-sites-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-index-ja-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/index.ja.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-index-ja-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-1-index-ja-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/test1/index.ja.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-1-index-ja-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-1-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/test1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-1-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-2-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/test2.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-2-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-2-ja-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/test2.ja.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-test-2-ja-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-testpage-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/testpage.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-testpage-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-testpage-ja-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/buildhome/repo/contents/testpage.ja.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-buildhome-repo-contents-testpage-ja-mdx" */)
}

