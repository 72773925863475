import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/contents/test2.en.mdx";
import React from "react";
import {Link} from "gatsby";
import Base from "../Base/Base";
import {MDXProvider} from "@mdx-js/react";
import {useTranslation} from 'react-i18next';
var pageLinks = [{
  text: "Root",
  url: "/",
  description: "Root.",
  color: "#E95800"
}, {
  text: "Test1",
  url: "/test1_slug",
  description: "Test1.",
  color: "#E95800"
}, {
  text: "Test2",
  url: "/test2_slug",
  description: "Test2.",
  color: "#E95800"
}];
var shortcodes = {
  Link: Link
};
var Blog = function Blog(props) {
  var data = props.data, children = props.children;
  var _useTranslation = useTranslation(), i18n = _useTranslation.i18n;
  console.log(i18n.language);
  return React.createElement(Base, {
    appBarTitle: data.mdx.frontmatter.title,
    pageProps: props
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children));
};
Blog
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Blog, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var pageQuery = "117692976";
