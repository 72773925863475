import * as React from "react"
import { HeadFC, Link } from "gatsby"
import type { PageProps } from "gatsby"
import Base from "./Base/Base"
import { useTranslation } from "react-i18next"
import { Button, TextField } from "@mui/material"

const pageStyles = {
  color: "#232129",
  // padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  // maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const doclistStyles = {
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  // maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  display: `inline-block`,
  marginBottom: 24,
  marginRight: 12,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLinks = [
  {
    text: "TypeScript Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
    color: "#8954A8",
  },
  {
    text: "GraphQL Typegen Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/local-development/graphql-typegen/",
    color: "#8954A8",
  }
]

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const Component: React.FC<PageProps> = (props) => {
  const {t, i18n} = useTranslation();
  console.log((props as any));
  return (<Base appBarTitle={t("Sign Up")} pageProps={props}>
    <main style={pageStyles}>
      <h1 style={headingStyles}>
        <span style={headingAccentStyles}>— {t("Connect with travel companion")} ❗🎉🎉🎉</span>
      </h1>
      <p style={paragraphStyles}>
        {t("Connect with travel companion on 20220908-NISHIDE.")}😎
      </p>
      <p style={paragraphStyles}>
      👍{t("It’s easy and safety.")}👍
      </p>
      <TextField id="outlined-basic" label={t("Name")} variant="outlined" margin="dense" />
      <br />
      <TextField id="outlined-basic" label={t("Mail Address")} variant="outlined" margin="dense" />
      <br />
      <TextField id="outlined-basic" label={t("Password")} variant="outlined" margin="dense" type="password" />
      <br />
      <TextField id="outlined-basic" label={t("Password Confirm")} variant="outlined" margin="dense" type="password" />
      <br />
      <Button variant="contained">{t("Sign Up")}</Button>
    </main></Base>
  )
}

export default Component

export const Head: HeadFC = () => <title>Home Page</title>
