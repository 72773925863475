/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "contents\\test2.en.mdx\r\n次test"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "2+2=", 2 + 2), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "pathname: ", props.location.pathname), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "slug: ", props.pageContext.slug), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "lang: ", props.pageContext.lang), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "title: ", props.pageContext.frontmatter.title), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "date: ", props.pageContext.frontmatter.date), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("div", null, "tags: ", props.pageContext.frontmatter.tags), "\n"), "\n"), "\n", React.createElement("ul", null, props.pageContext.frontmatter.tags.map(tag => React.createElement(_components.li, {
    key: tag
  }, tag))), "\n", console.log(props));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
